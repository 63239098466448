// @flow
import gql from 'graphql-tag';

const v2_get = gql`
  query AssetDetailsV2($id: ID!) {
    assets_details_v2(id: $id) {
      id
      asset_type
      asset_type_icon_url
      asset_type_name
      belongs_to_asset_group {
        id
        name
      }
      date_created
      date_no_longer_accessible
      discovered_via
      discovered_via_icon_url
      discovered_via_name
      hosted_on
      hosted_on_icon_url
      hosted_on_name
      name
      open_important_policy_violations_count
      open_proactive_policy_violations_count
      open_urgent_policy_violations_count
      status
      url
      belongs_to_cloud_account {
        id
        cloud_account_icon_url
        cloud_account_type_name
        customer_supplied_name
      }
    }
  }
`;

const list = gql`
  query AssetList(
    $cursor: String
    $include: String
    $filter_by_cloud_provider: [HostedOn]
    $filter_by_asset_type: [AssetTypeEnum]
    $filter_by_discovered_via: [DiscoveredVia]
    $filter_by_asset_group_id: [ID]
    $filter_by_is_shadow: Boolean
    $filter_by_asset_tags: String
    $filter_by_text: String
    $filter_by_ownership: String
    $filter_by_vendor_id: String
    $order_by: AssetOrderBy
    $page_size: Int
  ) {
    asset_list(
      cursor: $cursor
      include: $include
      filter_by_cloud_provider: $filter_by_cloud_provider
      filter_by_asset_type: $filter_by_asset_type
      filter_by_discovered_via: $filter_by_discovered_via
      filter_by_asset_group_id: $filter_by_asset_group_id
      filter_by_is_shadow: $filter_by_is_shadow
      filter_by_asset_tags: $filter_by_asset_tags
      filter_by_text: $filter_by_text
      filter_by_ownership: $filter_by_ownership
      filter_by_vendor_id: $filter_by_vendor_id
      order_by: $order_by
      page_size: $page_size
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      assets {
        id
        name
        url
        status
        asset_type
        asset_type_icon_url
        asset_type_name
        belongs_to_asset_group {
          id
          name
        }
        date_created
        date_no_longer_accessible
        discovered_via
        discovered_via_icon_url
        discovered_via_name
        hosted_on
        open_important_policy_violations_count
        open_proactive_policy_violations_count
        open_urgent_policy_violations_count
        included_cloud_ransomware_stats {
          last_scan_date
          total_files_count
          last_scan_change_files_count
          total_directories_count
          last_scan_change_directories_count
          is_publicly_writable
          is_publicly_readable
          is_malware_detected
          is_ransomware_detected
        }
        tags {
          id
          tag_id
          tag
          value
          imported_external_id
          imported_from
          imported_from_icon_url
        }
        hosted_on_icon_url
        hosted_on_name
        included_api_radar_stats {
          last_tested_date
          has_active_protection_enabled
          insecure_operations_count
          secure_operations_count
          total_operations_count
        }
        included_supply_chain_secure_details {
          related_mobile_apps_count
          related_web_apps_count
          vendor {
            id
            icon_url
            name
          }
        }
        included_api_custom_check_details {
          api_operation_exposed_methods
          api_operations_count
          onboarded_api_custom_check_types
        }
      }
    }
  }
`;

const assets_v2_list = gql`
  query AssetsAssetsListV2($asset_id: ID!, $cursor: String) {
    assets_assets_list_v2(asset_id: $asset_id, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      assets {
        id
        asset_type
        asset_type_icon_url
        asset_type_name
        belongs_to_asset_group {
          id
          name
        }
        date_created
        date_no_longer_accessible
        discovered_via
        discovered_via_icon_url
        discovered_via_name
        hosted_on
        hosted_on_icon_url
        hosted_on_name
        name
        open_important_policy_violations_count
        open_proactive_policy_violations_count
        open_urgent_policy_violations_count
        status
        url
      }
    }
  }
`;

const policy_violations_v2_list = gql`
  query AssetsPolicyViolationsV2List($asset_id: ID!, $cursor: String) {
    assets_policy_violations_list_v2(asset_id: $asset_id, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_violations {
        id
        status
        affected_asset_name
        affected_asset_date_created
        affected_asset_type_name
        date_created
        date_resolved
        exception_date_created
        violated_policy_rule {
          id
          relevance
          policy_rule_type {
            id
            title
            description
            recommendation
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
          }
          relevance
          status
        }
      }
    }
  }
`;

const asset_tags_get = gql`
  query AssetTags($uuid: ID!) {
    asset_tags(uuid: $uuid) {
      asset_tags {
        id
        tag_id
        tag
        value
        imported_external_id
        imported_from
        imported_from_icon_url
      }
    }
  }
`;

const asset_tags_put = gql`
  mutation AssetTagsPut($uuid: ID!, $body: AssetTagPutParams!) {
    asset_tags_put(uuid: $uuid, body: $body) {
      asset_tags {
        id
        tag_id
        tag
        value
        imported_external_id
        imported_from
      }
    }
  }
`;

export default {
  v2_get,
  list,
  assets: {
    v2_list: assets_v2_list,
  },
  policy_violations: {
    v2_list: policy_violations_v2_list,
  },
  asset_tags: {
    get: asset_tags_get,
    put: asset_tags_put,
  },
};
